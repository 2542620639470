<template>
    <div class="grid-item listing-detail-info-container">
        <div class="listing-detail-info-content">

            <!-- Digital Pricing Section -->
            <div
                :aria-label="showAllButtonText"
                :title="showAllButtonText"
                class="header-row"
                role="button"
                @click="showMoreHandler()"
            >
                <h2 class="detail-field-heading container-heading">
                    Digital Pricing
                </h2>
                <MaterialIcon
                    v-if="hasMoreDigitalPricesThanThreshold"
                    :class="{ 'arrow-pointed-right': !digitalPricing.showAllResults }"
                    class="blue-arrow"
                    iconName="arrow_drop_down"
                />
                <!-- Show All / Show Less -->
                <p
                    v-if="hasMoreDigitalPricesThanThreshold"
                    class="blue-underlined-text"
                >
                    {{ showAllShowLessLinkText }}
                </p>
                <p class="grey-text">Results: {{ digitalPricing.items.length }}</p>
            </div>

            <v-data-table
                :headers="digitalPricing.tableHeaders"
                :items="displayedDigitalPrices"
                :loading="digitalPricing.inFlight"
                :sortDesc="false"
                noDataText="No Digital Prices"
                sortBy="duration"
                hideDefaultFooter
            >
                <template #[`item.duration`]="{ item }">
                    {{ item.duration === -1 ? 'Lifetime' : item.duration }}
                </template>
                <template #[`item.price`]="{ item }">
                    ${{ FormatUtil.toCurrency(item.price) }}
                </template>
            </v-data-table>

            <!-- Group Titles Section -->
            <div class="title-group-container">
                <div class="grouped-titles-heading">
                    <h2 class="detail-field-heading container-heading">
                        Grouped Titles
                    </h2>
                    <router-link
                        :to="{ name: 'TitleGroupSearch', query: { q: productCode } }"
                        target="_blank"
                        class="title-group-link"
                    >
                        {{ groupedTitleLinkText }}
                        <v-icon size="medium" class="title-group-link__icon">mdi-open-in-new</v-icon>
                    </router-link>
                </div>
                <div class="grouped-titles-details">
                    <v-autocomplete
                        v-model="selectedStatus"
                        outlined
                        dense
                        hideDetails
                        backgroundColor="white"
                        label="Status"
                        :items="statusOptions"
                        itemText="friendly"
                        itemValue="name"
                    />
                    <v-text-field
                        id="title-group-date"
                        class="grouped-titles-details__date"
                        :value="lastGroupingStatusDate"
                        label="Last Grouping Check Date"
                        backgroundColor="white"
                        outlined
                        dense
                        hideDetails
                        disabled
                    />
                    <v-spacer />
                    <v-btn color="primary" @click="updateGroupStatus">Save</v-btn>
                </div>
            </div>

            <!-- Subscription Pricing Section -->
            <section id="subscription-pricing">
                <div
                    class="header-row"
                >
                    <h2 class="detail-field-heading container-heading">
                        Subscription Pricing
                    </h2>
                </div>
                <v-data-table
                    :headers="subscriptionPricingHeaders"
                    :items="subscriptionPricing"
                    :loading="subscriptionPricingLoading"
                    :sortDesc="false"
                    noDataText="No Subscription Prices"
                    sortBy="duration"
                    hide-default-footer
                >
                    <template #[`item.duration`]="{ item }">
                        {{ item.duration === -1 ? 'Lifetime' : item.duration }}
                    </template>
                    <template #[`item.digitalPrice`]="{ item }">
                        ${{ FormatUtil.toCurrency(item.digitalPriceDtos[0].digitalPrice) }}
                    </template>
                </v-data-table>
            </section>
        </div>
    </div>
</template>

<script>
// Vuex
import {
    mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';

// Api
import GroupApi from '@/api/GroupApi';
import TitleApi from '@/api/TitleApi';

// Utils
import ArrayUtil from '@/utils/ArrayUtil';
import FormatUtil from '@/utils/FormatUtil';

// Components
import MaterialIcon from '@/components/base/MaterialIcon';

export default {
    name: 'DigitalPrices',
    components: {
        MaterialIcon,
    },
    props: {
        productCode: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            FormatUtil,

            digitalPricing: {
                initialResultsShownCount: 5,
                showAllResults: false,
                items: [],
                inFlight: false,
                tableHeaders: [
                    {
                        text: 'EAN',
                        value: 'digitalIsbn',
                        sortable: true,
                    },
                    {
                        text: 'Duration ( days )',
                        value: 'duration',
                        sortable: true,
                        sort: this.sortDuration,
                    },
                    {
                        text: 'Price',
                        value: 'price',
                        sortable: true,
                    },
                    {
                        text: 'Provider',
                        value: 'digitalVendorName',
                        sortable: true,
                    },
                ],
            },

            relatedTitleData: {
                inFlight: false,
                titleGroupId: null,
                titleList: [],
            },
            callback: null,

            subscriptionPricing: [],
            subscriptionPricingLoading: false,
            subscriptionPricingHeaders: [
                {
                    text: 'Subscription',
                    value: 'friendly',
                    sortable: true,
                },
                {
                    text: 'Price',
                    value: 'digitalPrice',
                    sortable: true,
                },
                {
                    text: 'Duration (days)',
                    value: 'duration',
                    sortable: false,
                },
            ],
        };
    },
    computed: {
        ...mapGetters( {
            authToken: 'User/authString',
        } ),
        ...mapState( {
            statusModified: ( state ) => state.TitleGroup.statusModified,
            statusOptions: ( state ) => state.TitleGroup.statusOptions,
            currentTitle: ( state ) => state.currentTitle,
            currentStatus: ( state ) => state.TitleGroup.selectedStatus,
        } ),
        /** @returns { Array } */
        displayedDigitalPrices() {
            return this.digitalPricing.showAllResults
                ? this.digitalPricing.items
                : this.digitalPricing.items.slice( 0, this.digitalPricing.initialResultsShownCount );
        },
        /** @returns { Boolean } */
        hasMoreDigitalPricesThanThreshold() {
            return this.isOverThreshold( this.digitalPricing.items );
        },
        /** @returns { String } */
        showAllShowLessText() {
            return this.digitalPricing.showAllResults
                ? 'Show Less'
                : 'Show All';
        },
        /** @returns { String } */
        showAllButtonText() {
            return this.hasMoreDigitalPricesThanThreshold
                ? this.showAllShowLessText
                : 'All Prices Displayed';
        },
        /** @returns { String } */
        showAllShowLessLinkText() {
            return this.hasMoreDigitalPricesThanThreshold
                ? this.showAllShowLessText
                : '';
        },
        /** @returns { Boolean } */
        titleBelongsToAGroup() {
            return this.relatedTitleData.titleList.length > 0;
        },
        /** @returns { String } */
        groupedTitleLinkText() {
            if ( this.relatedTitleData.inFlight ) {
                return 'Loading...';
            }
            return this.titleBelongsToAGroup
                ? `View all titles in this group: ${ this.relatedTitleData.titleList.length }`
                : 'This title is ungrouped.';
        },
        /** @returns { String } */
        lastGroupingStatusDate() {
            return this.currentTitle?.titleGroupingDTO?.lastGroupingCheckDate
                ? new Date( this.currentTitle?.titleGroupingDTO?.lastGroupingCheckDate ).toLocaleDateString()
                : 'Grouping Never Checked';
        },
        statusDirty: {
            get() {
                return this.statusModified;
            },
            set( val ) {
                this.setStatusModified( val );
            },
        },
        selectedStatus: {
            get() {
                return this.currentStatus;
            },
            set( val ) {
                this.setSelectedStatus( val );
            },
        },
    },
    watch: {
        // Immediately hit this handler when this.productCode changes
        productCode: {
            handler: 'init',
            immediate: true,
        },
        selectedStatus: {
            /** @param { String } newVal */
            handler( newVal ) {
                this.statusDirty = newVal !== this.currentTitle?.titleGroupingDTO?.groupingStatusName;
            },
        },
        currentTitle: {
            handler( newVal ) {
                this.selectedStatus = newVal?.titleGroupingDTO?.groupingStatusName ?? '';
                this.statusDirty = false;
            },
            immediate: true,
        },
    },
    mounted() {
        this.getGroupStatusOptions();
    },
    methods: {
        ...mapMutations( {
            setStatusModified: 'TitleGroup/SET_STATUS_MODIFIED',
            setSelectedStatus: 'TitleGroup/SET_SELECTED_STATUS',
        } ),
        ...mapActions( {
            updateGroupStatus: 'TitleGroup/updateGroupStatus',
            getGroupStatusOptions: 'TitleGroup/getGroupStatusOptions',
        } ),
        init() {
            this.getTitlePrices();
            this.getTitlesInSameGroup();
            this.fetchSubscriptionPricing();
        },
        clearRelatedTitleData() {
            this.relatedTitleData.titleGroupId = null;
            this.relatedTitleData.titleList = [];
        },

        // sort such that -1 (aka 'Lifetime') is treated as the greatest value
        sortDuration( a, b ) {
            if ( a === -1 ) {
                return 1;
            }
            if ( b === -1 ) {
                return -1;
            }
            return a - b;
        },

        isOverThreshold( arrayable ) {
            return !!(
                arrayable
                && arrayable.length
                && ( arrayable.length > this.digitalPricing.initialResultsShownCount )
            );
        },

        showMoreHandler() {
            // No need to toggle anything if they don't have enough prices
            if ( this.isOverThreshold( this.digitalPricing.items ) ) {
                this.digitalPricing.showAllResults = !this.digitalPricing.showAllResults;
            }
        },

        async getTitlePrices() {
            this.digitalPricing.inFlight = true;
            this.digitalPricing.items = [];
            try {
                const { data } = await TitleApi.getTitlePrices( {
                    authToken: this.authToken,
                    productCode: this.productCode,
                    catalogListingId: null,
                } );

                this.digitalPricing.items = ArrayUtil
                    .sortBy( data?.digitalPrices ?? [], 'price', null, true )
                    .sort( ( a, b ) => this.sortDuration( a.duration, b.duration ) );

                this.digitalPricing.showAllResults = !( this.isOverThreshold( this.digitalPricing.items ) );
            } catch ( error ) {
                this.digitalPricing.items = [];
                const message = `Failed to get additional prices for this title - ${ error?.response?.data?.message ?? error?.message }`;
                await this.$store.dispatch( 'setErrorNotification', message, { root: true } );
            } finally {
                this.digitalPricing.inFlight = false;
            }
        },

        async getTitlesInSameGroup() {
            this.clearRelatedTitleData();
            this.relatedTitleData.inFlight = true;
            try {
                const { data } = await GroupApi.getRelatedTitlesInGroup( {
                    authToken: this.authToken,
                    isbn: this.productCode,
                } );
                this.relatedTitleData.titleGroupId = data?.titleGroupId ?? null;
                this.relatedTitleData.titleList = data?.titleDTOList
                    .map( ( item ) => ( {
                        ...item,
                        descriptionFriendly: FormatUtil.truncateText( item.description, 40 ),
                        titleType: FormatUtil.capitalizeString( item.titleType ),
                    } ) ) ?? [];
            } catch ( error ) {
                this.clearRelatedTitleData();
            } finally {
                this.relatedTitleData.inFlight = false;
            }
        },

        /**
         * Gets subscription pricing only saving titles that had digital pricing
         */
        async fetchSubscriptionPricing() {
            this.subscriptionPricingLoading = true;

            try {
                const hasPricing = (digitalPriceDtos) => digitalPriceDtos.length > 0;
                this.subscriptionPricing = (
                    await TitleApi.getSubscriptionPricing(this.authToken, this.productCode)
                ).data.filter(({ digitalPriceDtos }) => hasPricing(digitalPriceDtos));
            } catch (error) {
                console.log(error);
            } finally {
                this.subscriptionPricingLoading = false;
            }
            return this.subscriptionPricing;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables.scss";
@import "@/assets/sass/digital.scss";

$link-color: #66c0ec;

.grid-item {
    background-color: $grid-item-bg;
}

.header-row {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.container-heading {
    font-variant-caps: all-small-caps;
    margin: 0 0.5rem 0 0;
}

.blue-arrow {
    color: $edit-blue;
    font-size: 24px;
    margin: auto;
    margin-left: 0;
}

.arrow-pointed-right {
    transform: rotate(-90deg);
}

.blue-underlined-text {
    // a class named as an homage to a particular Russian's code
    color: $link-color;
    text-decoration: underline;
    margin-right: 1rem;
}

.no-results {
    display: flex;
    justify-content: space-evenly;

    > p {
        margin-top: 1rem;
    }
}

.grey-text {
    color: #656565;
}

.title-group-container {
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    border-top: 1px solid #cdcdcd;
    padding-top: 1.5rem;

    .grouped-titles-heading {
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 1rem;

        .title-group-link {
            margin: 0 0 0 auto;
            color: $link-color;
            font-style: italic;
            font-size: 16px;
            text-decoration: none;
            display: flex;
            align-items: center;
            gap: 0.5rem;

            &__icon {
                color: inherit;
            }
        }
    }

    .grouped-titles-details {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        &__date {
            ::v-deep .theme--light {
                &.v-label--is-disabled {
                    color: rgba(0,0,0,.6) !important;
                }
            }
        }
    }

}
::v-deep #title-group-date {
    color: black !important;
}

#subscription-pricing {
    margin-top: 2rem;
}
</style>
