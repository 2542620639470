<script>
import { mapState, mapActions } from 'vuex';

// Utils
import ArrayUtil from '@/utils/ArrayUtil';
import WordCloudBase from '@/components/base/wordcloud/WordcloudBase';

export default {
    name: 'VendorLibraries',
    components: { WordCloudBase },
    props: {
        selectedLibraries: {
            type: Array,
            default: () => [],
        },
        titleIsbn: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            showSelectableOptions: false,
            optionKey: '',
            removeDialog: false,
            payload: {
                libraryName: '',
                isbn: '',
            },
        };
    },
    computed: {
        ...mapState({
            libraries: (state) => state.GlobalResources.vendorLibraries,
        }),

        /** @returns { Boolean } */
        hasLibraries() {
            return this.libraries.length > 0;
        },
    },
    methods: {
        ...mapActions({
            addTitleToLibrary: 'Title/LibraryManagement/addTitleToLibrary',
            removeTitleFromLibrary: 'Title/LibraryManagement/removeTitleFromLibrary',
        }),

        isIncluded(library) {
            return ArrayUtil.includes(this.selectedLibraries, library, 'name');
        },

        toggleSelection(library) {
            const selectedItems = [...this.selectedLibraries];
            if (this.isIncluded(library)) {
                ArrayUtil.remove(selectedItems, library, 'name');
            } else {
                selectedItems.push(library);
            }

            const selectedLibraryId = this.selectedLibraries.map((item) => item.id);
            const updatedItemId = selectedItems.map((item) => item.id);

            if (!ArrayUtil.isEqual(selectedLibraryId, updatedItemId)) {
                this.payload = {
                    libraryName: library.name,
                    isbn: this.titleIsbn,
                };
                if (this.isIncluded(library)) {
                    this.removeDialog = true;
                } else {
                    this.addTitleToLibrary(this.payload);
                }
            }
        },
    },
};
</script>

<template>
    <div>
        <v-hover v-slot="{ hover }">
            <div class="multi-option-base">
                <!-- Heading -->
                <div class="multi-option-base__heading">
                    <h5 class="column-title-heading">Auto-Managed Libraries</h5>
                    <v-btn
                        v-if="!hover"
                        text
                        icon
                        class="multi-option-base__icon"
                        color="accent"
                    >
                        <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                </div>

                <!-- WordCloud -->
                <WordCloudBase
                    v-show="!hover"
                    :itemList="selectedLibraries"
                    displayedKey="friendly"
                />

                <!-- Selectable Options -->
                <div v-if="hasLibraries && hover">
                    <div class="select-include-only">
                        <div id="display-single-list">
                            <ul class="option-list">
                                <li
                                    v-for="(library, index) in libraries"
                                    :key="index"
                                    class="option"
                                    :class="{ included: isIncluded(library) }"
                                    @click="toggleSelection(library)"
                                    @mousedown.prevent
                                >
                                    <v-icon small class="included-designator"> mdi-check </v-icon>
                                    {{ library.friendly }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <p v-else-if="!hasLibraries && hover">No Libraries Available</p>
            </div>
        </v-hover>

        <v-dialog
            v-model="removeDialog"
            width="500"
        >
            <v-card id="edit-notice-card">
                <v-card-title>
                    <v-spacer />
                    <v-btn icon @click="removeDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <div class="notice-header text-center">
                        <v-icon color="info" class="mr-3">
                            mdi-information-outline
                        </v-icon>
                        <p>Library Removal Notice</p>
                    </div>

                    <p class="pt-5 notice-message">
                        You are about to remove this title from {{ payload.libraryName }} library.
                        Are you sure you want to do this?
                    </p>
                </v-card-text>

                <v-card-actions class="pt-5">
                    <v-spacer />
                    <v-btn
                        color="primary"
                        @click="() => {
                            removeTitleFromLibrary(payload);
                            removeDialog = false;
                        }"
                    >
                        <v-icon>mdi-check</v-icon>&nbsp;
                        Yes
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="removeDialog = false"
                    >
                        <v-icon>mdi-cancel</v-icon>&nbsp;
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<style lang="scss">
#edit-notice-card {
    .v-card__text {
        margin-top: -3rem;
    }

    .notice-header {
        padding: 2rem 0;

        .v-icon {
            font-size: 5rem;
            margin-bottom: 1rem;
        }

        p {
            font-size: 1.75rem;
            font-weight: 600;
        }
    }

    .notice-message {
        font-size: 1rem;
    }

    .v-btn {
        padding: 0.5rem 1rem;
    }
}
</style>